.container {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}

.content {
    padding-top: 110px;
    text-align: center;
}

.content blockquote {
    padding: 1rem;
    margin-top: -1rem;
    margin-right: 8rem;
    margin-bottom: -1rem;
    margin-left: 8rem;
}

.content blockquote p {
    padding: 15px;
    background: #eee;
    border-radius: 5px;
}

.content blockquote p::before {
    content: "\201C";
}

.content blockquote p::after {
    content: "\201D";
}

@media (max-width: 700px) {
    .content blockquote {
        padding: 1rem;
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .content blockquote p {
        padding: 4px;
        background: #eee;
        border-radius: 5px;
    }
}
