.avatar {
  /* Rounded border */
  border-radius: 50%;
  height:128px;
  width: 128px;
  margin: auto;
}

.image {
  /* Rounded border */
  border-radius: 50%;

  /* Take full size */
  height: auto;
  width: 100%;
}
