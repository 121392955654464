.card {
  position: relative;

  height: 20rem;
  width: 20rem;

  border-style: solid;
  border-color: #d1d5db;
  border-radius: 0.5rem;
}

.card::before {
  background: #d1d5db;
  content: "";

  /* Position */
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(1rem, 1rem);

  /* Size */
  height: 100%;
  width: 100%;

  /* Display under the main content */
  z-index: 0;
}

.card__header {
  height: 4rem;
  width: 100%;
}
.card__footer {
  height: 2rem;
  width: 100%;
}

.card__body {
  height: 10rem;
  width: 100%;
  word-wrap: break-word;
  
}	

p::after {
    content: ' ';
    white-space: pre;
}

.card__content {
  /* Position */
  left: 0;
  position: absolute;
  top: 0;

  /* Size */
  height: 100%;
  width: 100%;

  z-index: 1;

  background: #fff;
}
