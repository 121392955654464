.container {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}

.content {
    padding-top: 100px;
    text-align: center;
    overflow: hidden;

}

.iframe {
    position: fixed;
    top: 75px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 1px;
    min-width: 100%;
    border: none;
}

body {
    overflow: hidden;
}
