.Toast {
    /* container */
    text-align: center;
    font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;

    /* botom-right */
    bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;

    /* notification body */
    text-align: center;
    background: #fff;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 30px;
	margin-bottom: 15px;
	width: 250px;
	max-height: 80px;
	border-radius: 4px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
}

