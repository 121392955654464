.container {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.content {
  padding-top: 100px;
  text-align: center;
}

.section {
  /* Take full size */
  height: 100%;
  width: 100%;

  /* Stick to the top */
  position: -webkit-sticky;
  position: sticky;
}

.name {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-size: 36px;
  font-weight: 500;
  transition: 0.5s;
}

.about {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.label {
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 5px;
}

.button {
  background: #282c34;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  color: #fff !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}
