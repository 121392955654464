.container {
  /* height: 100vh; */
  height: calc(100vh - 10px);
  width: 100vw;
  background-color: #fff;
  overflow:scroll;

}

.content {
  padding-top: 60px;
  text-align: center;
}

.card_layout {
  display: flex;
  justify-content: center;

  /* Put a card in the next row when previous cards take all width */
  flex-wrap: wrap;
}

.card_layout__item {
  display: flex;
  justify-content: center;
  align-items: center;

  /* There will be 4 cards per row */
  flex: 0 0 21%;

  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 2rem;
}
