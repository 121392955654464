.circle {
    border-radius: 50%;
}

.small {
    height: 36px;
    width: 36px;
}

.medium {
    height: 48px;
    width: 48px;
}

.large {
    height: 72px;
    width: 72px;
}